import { useForm } from 'day8-react';
import qs from 'query-string';
import { useLuckyModal } from "../../context/LuckyModalContext";
import { useState, useMemo } from "react";
import Calendar from "./steps/Calendar";
import Crew from "./steps/Crew";
import Destinations from "./steps/Destinations";
import ModalHead from "./components/ModalHead";
import { Button, Dialog, toast } from 'day8-ui';
const STEPS = {
  DESTINATIONS: 0,
  CALENDAR: 1,
  CREW: 2,
};
const stepComponents = {
  [STEPS.DESTINATIONS]: Destinations,
  [STEPS.CALENDAR]: Calendar,
  [STEPS.CREW]: Crew
};

export type LuckyModalFormData = {
  month: number | null;
  hostess: number;
  capacity: number;
  yearFilter: number;
  destinationFilter: number;
  cabinBookingType: boolean;
  customYachts: boolean;
}
export type ModalHeadProps = {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
}

const LuckyModal = () => {
  const [modalHeadProps, setModalHeadProps] = useState<ModalHeadProps | null>(null);
  const { formData, handleChange } = useForm({
    month: null,
    capacity: 8,
    hostess: 0,
    destinationFilter: 0,
    cabinBookingType: null,
    customYachts: null,
    yearFilter: null
  });
  const [step, setStep] = useState(STEPS.DESTINATIONS);
  const { isModalOpen, toggle, hasFilterCookie, clearFilterCookie } = useLuckyModal();
  const actionLabel = useMemo(() => {
    if (step === Object.keys(STEPS).length - 1) {
      return 'See options';
    }
    return 'Next';
  }, [step]);
  const secondaryActionLabel = useMemo(() => {
    if (step === 0) {
      return undefined;
    }
    return 'Back';
  }, [step]);
  const Component = stepComponents[step];
  const onNext = () => {
    if (step === Object.keys(STEPS).length - 1) {
      return buildQueryParams();
    }
    // if (step === STEPS.CALENDAR && formData.month === null) {
    //   toast.error('Please pick a month');
    //   return;
    // }
    setStep(value => value + 1);
  };
  const onBack = () => {
    setStep(value => value - 1);
  };
  const buildQueryParams = () => {
    const girls = Math.round(formData.capacity / 2);
    const boys = formData.capacity - girls;
    const query = {
      ...formData,
      girls,
      boys
    };
    delete query['capacity'];
    delete query['customYachts'];
    if (formData.destinationFilter === 0) {
      delete query['destinationFilter'];
    }
    const url = qs.stringifyUrl({
      url: formData.customYachts ? '/browse/yachts' : '/browse',
      query: query
    }, { skipNull: true });
    window.location.href = url;
  };
  if (hasFilterCookie) {
    return (
      <Dialog open={isModalOpen} onOpenChange={toggle} scrollable>
        <div className='d8-c-lucky-modal'>
          <ModalHead title="Continue search?" toggle={toggle} />
          <div className='dui-flex dui-gap-4 dui-mt-4'>
            <Button className="dui-w-full" buttonType='SECONDARY' onClick={clearFilterCookie}>Clear search</Button>
            <Button className="dui-w-full" onClick={() => window.location.href = '/browse'}>Continue</Button>
          </div>
        </div>
      </Dialog>
    )
  }
  return (
    <Dialog
      className='!dui-p-0'
      open={isModalOpen}
      onOpenChange={toggle}
      scrollable
      showFooter
      title={modalHeadProps && <ModalHead {...modalHeadProps} toggle={toggle} />}
      footerComponent={(
        <div className='dui-sticky dui-bottom-0 dui-z-10 dui-bg-white dui-px-8 dui-pb-8 dui-flex dui-mt-4 dui-gap-4'>
          <Button className="dui-w-full" buttonType='SECONDARY' onClick={() => secondaryActionLabel ? onBack() : toggle()}>{secondaryActionLabel || 'Close'}</Button>
          <Button className="dui-w-full" onClick={onNext}>{actionLabel}</Button>
        </div>
      )}>
      <div className='dui-px-8 d8-c-lucky-modal'>
        <Component formData={formData} handleChange={handleChange} setModalHeadProps={setModalHeadProps} onNext={onNext} />
      </div>
    </Dialog>
  )
};

export default LuckyModal;