import { Button } from 'day8-ui';
import { Fragment } from 'react';
import { CiSearch } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";


type ModalHeadProps = {
  icon?: React.ReactNode,
  title: string,
  subtitle?: string
  toggle: () => void
}
const ModalHead: React.FC<ModalHeadProps> = ({ icon, title, subtitle, toggle }) => {
  return (
    <Fragment>
      <div className="dui-p-8 dui-sticky dui-top-0 dui-z-10 dui-bg-white">
        <div className='dui-flex dui-justify-between dui-items-center'>
          <div className='dui-text-2xl dui-flex dui-items-center dui-gap-4'>
            {icon ?? <CiSearch />}
            <h2 className=''>{title}</h2>
          </div>
          <Button buttonType='LINK' onClick={toggle}><RxCross1 /></Button>
        </div>
        {subtitle && <p className='dui-mt-4'>{subtitle}</p>}
      </div>
      {/* <hr className="margin-y-md" style={{ height: '1px', background: 'var(--color-contrast-low)' }} /> */}
    </Fragment>
  );
};

export default ModalHead;