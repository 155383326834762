import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { COOKIES } from 'day8-react/build/constants';
import { fetchWeekAutomaticDiscounts, getTYWDestinations } from '../helpers/api';
import { TYWDestinationType } from '../globalTypes';

type Props = {
  children: React.ReactNode
}
type InitialValues = {
  isModalOpen: boolean
  open: () => void
  close: () => void
  toggle: () => void
  clearFilterCookie: () => void
  getDiscountForDestination: (id: number) => number | null
  destinations: TYWDestinationType[]
  hasFilterCookie: boolean
  weekAutomaticDiscounts: { [key: number]: string }
}
export const initialValues: InitialValues = { isModalOpen: false, open: () => { }, close: () => { }, toggle: () => { }, destinations: [], hasFilterCookie: false, clearFilterCookie: () => { }, getDiscountForDestination: (id: number) => null, weekAutomaticDiscounts: {} };
export const LuckyModalContext = createContext(initialValues);

export const LuckyModalProvider: React.FC<Props> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [destinations, setDestinations] = useState<TYWDestinationType[]>([]);
  const [weekAutomaticDiscounts, setWeekAutomaticDiscounts] = useState<{ [key: number]: string }>({});
  const [hasFilterCookie, setHasFilterCookie] = React.useState(false);
  useEffect(() => {
    // handle filter from cookie
    const cookieData = Cookies.get(COOKIES.FILTER);
    if (cookieData) {
      setHasFilterCookie(true);
    }
    const fetchDestinations = async () => {
      const destinations = await getTYWDestinations();
      const discounts = await fetchWeekAutomaticDiscounts();
      setWeekAutomaticDiscounts(discounts);
      setDestinations(destinations);
    };
    fetchDestinations();
  }, []);
  const getDiscountForDestination = (destinationId: number) => {
    if (weekAutomaticDiscounts) {
      // get week IDs from destination
      const weekIds = destinations?.find(d => d.id === destinationId)?.weeks.map(w => w.id);
      // find weeks with discounts that belongs to destination
      const discounts = Object.keys(weekAutomaticDiscounts)?.filter(w => weekIds?.includes(+w)).map((weekId) => weekAutomaticDiscounts[+weekId]);
      // get max discount from found week discounts
      if (discounts.length > 0) {
        const maxDiscount = Math.max(...discounts.map(discount => +discount));
        return maxDiscount;
      }
    }
    return null;
  }

  const open = () => {
    setIsModalOpen(true);
  };

  const close = () => {
    setIsModalOpen(false);
  };
  const clearFilterCookie = () => {
    Cookies.remove(COOKIES.FILTER);
    setHasFilterCookie(false);
  };

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <LuckyModalContext.Provider value={{ isModalOpen, open, close, toggle, destinations, hasFilterCookie, clearFilterCookie, getDiscountForDestination, weekAutomaticDiscounts }}>
      {children}
    </LuckyModalContext.Provider>
  );
};

export const useLuckyModal = () => useContext(LuckyModalContext);
