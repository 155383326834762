import { Pill } from "day8-ui";
import { IoIosGlobe } from "react-icons/io";
import { LuckyModalFormData, ModalHeadProps } from '../LuckyModal';
import { useLuckyModal } from '../../../context/LuckyModalContext';
import { useEffect } from 'react';

type DestinationsProps = {
  formData: LuckyModalFormData;
  handleChange: (e: any) => void
  setModalHeadProps: (props: ModalHeadProps | null) => void
  onNext: () => void
}

const Destinations: React.FC<DestinationsProps> = ({ handleChange, setModalHeadProps, onNext }) => {
  const { destinations, getDiscountForDestination } = useLuckyModal();
  useEffect(() => {
    setModalHeadProps({
      icon: <IoIosGlobe />,
      title: "Where?",
      subtitle: "Choose a destination or if you're unsure go straight to the next step to see all dates.",
    })
  }, [])

  const handleDestinationSelect = (destinationId: number | null) => {
    handleChange({ target: { value: destinationId, name: 'destinationFilter' } });
    onNext();
  };

  return (
    <div className='step-destinations'>
      <div className="dui-grid dui-grid-cols-2 dui-gap-4">
        {destinations.map(destination => {
          const discount = getDiscountForDestination(destination.id);
          return (
            <div key={destination.id} className="dui-col-span-2 sm:dui-col-span-1 dui-relative dui-aspect-video dui-transition hover:dui-opacity-75 dui-cursor-pointer" onClick={() => handleDestinationSelect(destination.id)}>
              <img
                src={destination.img || "https://assets.theyachtweek.com/wp-content/uploads/2024/08/home-header.png"}
                alt={destination.name || "Main gallery image"}
                className="dui-absolute dui-w-full dui-h-full dui-object-cover dui-top-[50%] dui-left-[50%] dui-translate-x-[-50%] dui-translate-y-[-50%] dui-z-0"
              />
              <div className="dui-absolute dui-w-full dui-h-full dui-bg-black dui-opacity-15" />
              <div className="dui-relative dui-h-full dui-flex dui-flex-col dui-gap-4 dui-justify-end dui-p-4">
                {discount && (
                  <Pill
                    className="dui-mb-4 dui-flex-grow dui-self-start dui-bg-primary-950"
                  >
                    Claim up to {discount}% Off
                  </Pill>
                )}
                <div className="">
                  <h3 className="dui-text-2xl dui-font-semibold dui-mb-p2 dui-uppercase dui-text-white">
                    {destination.name}
                  </h3>
                  <p className="dui-text-white">
                    {destination.dates}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default Destinations;